/**
 * FWS
 * - A frontend wrapper for the low level ws library.
 * - A singleton instance for managing all websocket connections and messages.
 * - Standardizes json parsing and stringification.
 * - Standard event/data message flow between server and client.
 * - A clean publish subscribe design pattern similar to jquery.
 */

import { WSEvent } from "../constants";

class FWS {
  queue = [];
  subscribeQueue = [];
  delay = 100;
  enableRetry = false;
  onConnected = null;
  onDisconnected = null;

  /**
   * callbacks
   * Key: The name of event.
   * Value: An array of callbacks to run on triggering of event.
   */

  callbacks = {};

  /**
   * The FWS "onready" function.
   *
   * The callback should contain initialization code for event subscriptions.
   * See examples.
   * @param {function} cb
   */

  init(config, cb) {
    this.config = config;
    this.enableRetry = true;

    // Always reset the subscribe queue when starting a new page.
    this.subscribeQueue = [];

    if (!config.websocketHost) {
      throw "websocketHost undefined.";
    }

    if (!this.ws) {
      this.initWebsocket(cb);
    } else {
      cb();
    }
  }

  /**
   * An initialization function that instantiates a native WebSocket object.
   * @param {function} cb
   */

  initWebsocket(cb) {
    this.ws = new WebSocket(`${this.config.websocketHost}`);

    this.ws.onopen = () => {
      console.info("[Websocket] Connected");
      this.delay = 100;
      cb();

      if (this.onConnected) {
        this.onConnected();
      }
    };

    this.ws.onerror = (error) => {
      console.error(error);
    };

    // https://stackoverflow.com/questions/22431751/websocket-how-to-automatically-reconnect-after-it-dies
    this.ws.onclose = (e) => {
      console.info("[Websocket] Disconnected");

      if (this.onDisconnected) {
        this.onDisconnected();
      }

      if (!this.enableRetry) {
        return;
      }
      // exponential backoff
      this.delay = this.delay * 2;
      console.info(
        `[Websocket] Reconnect will be attempted in ${this.delay} ms.`,
        e.reason
      );
      setTimeout(() => {
        this.initWebsocket(() => {
          this.reSubscribe();
        });
      }, this.delay);
    };

    this.ws.onmessage = (e) => {
      try {
        const payload = JSON.parse(e.data);
        const { event, data } = payload;
        const array = this.callbacks[event];
        if (Array.isArray(array)) {
          array.forEach((cb) => {
            cb(data);
          });
        }
      } catch (error) {
        console.error(error);
      }
      return false;
    };
  }

  send(eventName, data) {
    const payload = {
      event: eventName,
      data,
    };

    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(payload));
      this.addSubscribeQueue(payload);
    } else {
      console.info(
        `[Websocket] Not in a ready state ${
          this.ws ? this.ws.readyState : ""
        }. Add queue`,
        payload
      );

      // prevent excessive memory consumption if websocket disconnects
      if (this.queue.length < 100) {
        this.queue.push(payload);
      }
    }
  }

  subscribe(channel) {
    this.send(WSEvent.SUBSCRIBE, { channel });
  }

  unsubscribe(channel) {
    this.send(WSEvent.UNSUBSCRIBE, { channel });
  }

  on(eventName, cb) {
    this.callbacks[eventName] = this.callbacks[eventName] || [];
    this.callbacks[eventName].push(cb);
  }

  off(eventName) {
    this.callbacks[eventName] = undefined;
  }

  close() {
    this.enableRetry = false;
    this.ws.close();
    clearInterval(this.interval);
  }

  /**
   * process websocket requests that were made before the websocket
   * readystate was open. this is called after successful authentication
   * with websocket service.
   */
  processQueue() {
    while (this.queue.length) {
      const cachedPayload = this.queue.shift();
      this.ws.send(JSON.stringify(cachedPayload));
      this.addSubscribeQueue(cachedPayload);
    }
  }

  /**
   * Remove unnecessary pairs of subscribe and unsubscribe.
   */
  cleanSubscribeQueue() {
    for (const [index, element] of this.subscribeQueue.entries()) {
      if (element.event !== WSEvent.SUBSCRIBE) {
        return;
      }

      const pairIndex = this.subscribeQueue.findIndex(
        (pairElement) =>
          pairElement?.event === WSEvent.UNSUBSCRIBE &&
          pairElement?.data?.channel === element?.data?.channel &&
          pairElement?.skip !== true
      );
      if (pairIndex >= 0) {
        this.subscribeQueue[index].skip = true;
        this.subscribeQueue[pairIndex].skip = true;
      }
    }

    this.subscribeQueue = this.subscribeQueue.filter(
      (element) => element.skip !== true
    );
  }

  /**
   * Re-subscribe when the websocket is re-connected.
   */
  reSubscribe() {
    this.cleanSubscribeQueue();
    for (const subscribePayload of this.subscribeQueue) {
      this.ws.send(JSON.stringify(subscribePayload));
    }
  }

  /**
   * Only track subscription when actaully sending a message (ws.send).
   */
  addSubscribeQueue(payload) {
    if (
      ![WSEvent.AUTHENTICATE, WSEvent.SUBSCRIBE, WSEvent.UNSUBSCRIBE].includes(
        payload?.event
      )
    ) {
      return;
    }

    this.subscribeQueue = this.subscribeQueue || [];

    // prevent excessive memory consumption
    if (this.subscribeQueue.length > 100) {
      return;
    }

    this.subscribeQueue.push(payload);
  }
}

const instance = new FWS();

export { FWS };

export default instance;
