import React, { ReactElement } from "react";
import { css } from "@emotion/react";

import variables from "../../styles/variables";
import CaretRightIcon from "../../public/static/icons/caret-right.svg";
import pxToRem from "../../styles/px-to-rem";
import { h4Regular } from "../../styles/typography";
import HeroButton from "../common/hero-button";
import { DEFAULT_LOGO_URL } from "../../constants";

const smallBreakPoint = pxToRem(817);

const wrapper = css`
  overflow: hidden;
  position: relative;
`;

const heroSection = css`
  position: relative;
  z-index: 1;
  background: url("https://storage.googleapis.com/iv-prod-static/background-pattern.svg")
    no-repeat right center ${variables.colorMainBlue};
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr auto;
  color: ${variables.colorWhite};

  @media (min-width: ${smallBreakPoint}) {
    background-position: center;
    min-height: ${pxToRem(600)};
  }
`;

const heroContent = css`
  position: relative;
  margin: ${variables.spaceXLarge} auto ${pxToRem(200)};

  @media (min-width: ${smallBreakPoint}) {
    max-width: ${pxToRem(1030)};
    width: 100%;
    margin: ${variables.spaceXLarge} auto;
    padding: ${pxToRem(140)} ${variables.spaceBase};
  }
`;

const logoWrapper = css`
  text-align: center;
  margin-bottom: ${variables.spaceSmall};

  @media (min-width: ${smallBreakPoint}) {
    display: none;
  }
`;

const logoFull = css`
  width: auto;
  height: ${pxToRem(15.2)};
  margin: 0 auto ${variables.spaceBase};
`;

const heroTitle = css`
  font-size: ${pxToRem(40)};
  line-height: ${40 / 36};
  font-weight: ${variables.fontWeightLight};
  margin-bottom: ${variables.spaceBase};
  text-align: center;

  @media (min-width: ${variables.mobileTabletMaxWidth}) {
    margin-bottom: ${variables.spaceLarge};
    max-width: ${pxToRem(580)};
    width: 100%;
    text-align: initial;
    font-size: ${pxToRem(60)};
    line-height: 1;
  }
`;

const heroActions = css`
  display: grid;
  grid-gap: ${variables.spaceSmall};
  justify-content: center;

  @media (min-width: ${smallBreakPoint}) {
    justify-content: start;
    grid-template-columns: auto auto;
  }
`;

const videoHeroButtonStyle = css`
  ${h4Regular};
  font-family: ${variables.marketingHeaderFontFamily};
  min-height: ${pxToRem(40)};
  padding: ${pxToRem(6)} ${pxToRem(24)};
  border-radius: ${variables.roundness2};
  color: ${variables.colorBrandYellow};
  transition:
    background-color ${variables.transitionSpeed} ease,
    color ${variables.transitionSpeed} ease,
    border-color ${variables.transitionSpeed} ease;
  border: ${pxToRem(2)} solid transparent;

  &:hover {
    color: ${variables.colorWhite};
    border: ${pxToRem(2)} solid ${variables.colorWhite};

    path {
      fill: ${variables.colorWhite};
    }
  }

  &:focus,
  &:active {
    opacity: ${variables.disabledOpacity};
  }

  &:disabled {
    opacity: ${variables.disabledOpacity};
    cursor: not-allowed;
  }

  @media (min-width: ${smallBreakPoint}) {
    border: ${pxToRem(2)} solid ${variables.colorBrandYellow};
    width: ${pxToRem(165)};
    padding: ${pxToRem(6)} ${pxToRem(16)};
  }
`;

const scheduleDemoButton = css`
  padding: ${pxToRem(6)} ${pxToRem(24)};

  @media (min-width: ${smallBreakPoint}) {
    padding: ${pxToRem(6)} ${pxToRem(16)};
  }
`;

const videoHeroButtonLayoutStyle = css`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
`;

const playButton = css`
  width: ${pxToRem(18)};
  height: ${pxToRem(18)};

  path {
    transition: fill ${variables.transitionSpeed} ease;
    fill: ${variables.colorBrandYellow};
  }
`;

const introSection = css`
  position: relative;
  z-index: 0;
  background: ${variables.colorWhite};
  padding: ${pxToRem(260)} ${variables.spaceBase} ${pxToRem(80)};

  @media (min-width: ${smallBreakPoint}) {
    padding: 0 ${variables.spaceBase} ${pxToRem(80)};
  }
`;

const introSectionContent = css`
  display: grid;
  grid-gap: ${variables.spaceXLarge};
  max-width: ${pxToRem(1030)};
  margin: 0 auto;
`;

const introSectionFirstBlock = css`
  display: grid;
  position: relative;
  padding: ${pxToRem(120)} 0 0;

  @media (min-width: ${smallBreakPoint}) {
    grid-template-columns: ${pxToRem(400)} 1fr;
  }
`;

const iPadMockup = css`
  z-index: -1;
  position: absolute;
  left: 50%;
  top: ${pxToRem(140)};
  width: ${pxToRem(700)};
  height: auto;
  transform: translate(-30%, 0);

  img {
    max-width: 100%;
  }

  @media (min-width: ${variables.mobileMaxWidth}) {
    top: ${pxToRem(200)};
    transform: translate(-50%, 0);
  }

  @media (min-width: ${smallBreakPoint}) {
    top: ${pxToRem(120)};
    left: ${pxToRem(400)};
    width: ${pxToRem(880)};
    transform: translate(0, 0);
  }
`;

const textureBg = css`
  z-index: -2;
  position: absolute;
  top: -${pxToRem(370)};
  left: -${pxToRem(700)};
  width: ${pxToRem(1440)};
  height: auto;

  img {
    max-width: 100%;
  }

  @media (min-width: ${smallBreakPoint}) {
    top: 0;
    left: -${pxToRem(220)};
  }
`;

const introSectionTitle = css`
  font-size: ${pxToRem(36)};
  font-weight: ${variables.fontWeightLight};
  color: ${variables.colorMainBlue};
  margin-bottom: ${variables.spaceMedium};

  @media (min-width: ${variables.mobileTabletMaxWidth}) {
    font-size: ${pxToRem(42)};
    line-height: 1;
  }
`;

const introSectionSubTitle = css`
  color: ${variables.colorMainBlue};
  font-weight: ${variables.fontWeightRegular};
  margin-bottom: ${variables.spaceMedium};
`;

const bodyText = css`
  ${h4Regular};
  line-height: 1.75;
  color: ${variables.colorSecondaryGray};
`;

const introSectionSecondBlock = css`
  display: grid;

  @media (min-width: ${smallBreakPoint}) {
    grid-template-columns: 2fr 1fr;
    align-items: center;
  }
`;

const teamIllustration = css`
  max-width: 100%;
`;

type Input = {
  onScheduleDemo: () => void;
  onPlayVideo: () => void;
};

const HeroSection = ({ onScheduleDemo, onPlayVideo }: Input): ReactElement => {
  return (
    <div css={wrapper}>
      <div css={heroSection}>
        <div css={heroContent}>
          <video
            loop={true}
            css={iPadMockup}
            poster="https://storage.googleapis.com/iv-prod-static/home-hero.png"
            autoPlay
            muted
          >
            <source
              src="https://storage.googleapis.com/iv-prod-static/home-hero.webm"
              type="video/webm"
            />
          </video>
          <div css={logoWrapper}>
            <img
              css={logoFull}
              src={DEFAULT_LOGO_URL}
              alt="Invent Value Logo"
            />
          </div>
          <h1 css={heroTitle}>Automate everyday innovation</h1>
          <div css={heroActions}>
            <HeroButton style={scheduleDemoButton} onClick={onScheduleDemo}>
              Schedule Demo
            </HeroButton>
            <button
              type="button"
              css={videoHeroButtonStyle}
              onClick={onPlayVideo}
            >
              <div css={videoHeroButtonLayoutStyle}>
                Watch Video
                <CaretRightIcon css={playButton} />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div css={introSection}>
        <div css={introSectionContent}>
          <div css={introSectionFirstBlock}>
            <picture css={textureBg}>
              <source
                srcSet="https://storage.googleapis.com/iv-prod-static/texture-bg.webp"
                type="image/webp"
              />
              <source
                srcSet="https://storage.googleapis.com/iv-prod-static/texture-bg.png"
                type="image/png"
              />
              <img
                src="https://storage.googleapis.com/iv-prod-static/texture-bg.png"
                alt="Background texture"
              />
            </picture>

            <div>
              <h2 css={introSectionTitle}>
                Transform your team’s creativity into valuable results
              </h2>
              <p css={bodyText}>
                Invent Value is a collaborative innovation platform that
                empowers organizations to tap the mindshare of their people for
                gathering diverse input, generating new ideas, and evaluating
                possible solutions to complex challenges.
              </p>
            </div>
          </div>
          <div css={introSectionSecondBlock}>
            <div>
              <video
                loop={true}
                css={teamIllustration}
                poster="https://storage.googleapis.com/iv-prod-static/team-illustration.svg"
                autoPlay
                muted
              >
                <source
                  src="https://storage.googleapis.com/iv-prod-static/home-team.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div>
              <h2 css={introSectionSubTitle}>Build an innovation engine</h2>
              <p css={bodyText}>
                An innovative culture is much more than technology, it involves
                putting workspace around your processes and people that spurs
                ongoing engagement and action. Invent Value conveniently puts
                all the pieces in place to help innovation thrive continually.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
