// https://github.com/zeit/next.js#custom-app

import React, { ReactElement, useEffect } from "react";
import { NextComponentType } from "next";
import { AppContext, AppInitialProps, AppProps } from "next/app";
import { Global } from "@emotion/react";
import Modal from "react-modal";
import NextHead from "next/head";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import globalStyles from "../styles/global";
import isBrowser from "../helpers/is-browser";

import { init } from "../helpers/sentry";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

init();

if (isBrowser()) {
  Modal.setAppElement("#__next");
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  require("focus-visible");
}

type Input = {
  Component: any;
  pageProps: any;
};

const MyApp: NextComponentType<AppContext, AppInitialProps, AppProps> = ({
  Component,
  pageProps,
}: Input): ReactElement => {
  useEffect(() => {
    console.info(`Version: ${process.env.NEXT_PUBLIC_COMMIT_SHA ?? "-"}`);
  }, []);
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <NextHead>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#fed851" />
          <meta name="msapplication-TileColor" content="#00507d" />
          <meta name="theme-color" content="#00507d" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
        </NextHead>
        <Global styles={globalStyles} />
        <ToastContainer
          position={toast.POSITION.TOP_CENTER}
          autoClose={3000}
          hideProgressBar={true}
          transition={Slide}
        />
        <Component {...pageProps} />
      </DndProvider>
    </>
  );
};

export default MyApp;
